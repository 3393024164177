<template>
  <div class="flex h-full">
    <loader :loading="loading" :backdrop="true"/>
    <div class="side-panel">
      <DetailsSidePanel>
        <template v-slot:heading>shared profile data block details</template>
        <template v-slot:text>Edit the data block updating data record values.</template>
      </DetailsSidePanel>
    </div>
    <div class="w-2/3 my-4">
      <shared-profile-data-form
        :initial-values="initialValues"
        :on-submit="handleSubmit"
        @close="redirectToList"
        edit-mode
      />
    </div>
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Loader from '@/components/ui/Loader';
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import SharedProfileDataForm from "@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataForm";

  export default {
    name: "SharedProfileDataEdit",
    components: {
      SharedProfileDataForm,
      DetailsSidePanel,
      Loader
    },
    mixins: [ModalNavigation, NotifyMixin],
    data(){
      return {
        initialValues: {},
        loading: false,
      }
    },
    computed: {
      basePath() {
        return this.$route.name.replace('dataBlocks.edit', 'details');
      },
      profileId() {
        return this.$route.params.profileId;
      },
      name() {
        return this.$route.params.name;
      },
      requestParams() {
        return {
          name: this.name,
          profileId: this.profileId,
          version: this.$route.params.version,
        }
      },
    },
    methods: {
      fetchSharedDataBlock() {
        this.loading = true;
        this.$raaDataProvider.get('profileDataBlockVersioned', { ...this.requestParams })
          .then(data => {
            this.initialValues = data;
          })
          .catch(error => {
            this.notifyError(error.message);
            this.redirectToList();
          })
          .finally(() => this.loading = false);
      },
      redirectToList() {
        this.$router.replace({
          name: this.basePath,
          params: {direction: 'back', profileId: this.profileId}
        });
      },
      handleSubmit({ description, records }) {
        if (!this.loading) {
          this.loading = true;
          return this.$raaDataProvider.update('profileDataBlockVersioned', {
            data: {
              description,
              records,
            },
            ...this.requestParams
          })
            .then(() => {
              this.notifySuccess('Data block has been updated');
              this.redirectToList();
            })
            .catch(error => this.notifyError(error.message))
            .finally(() => this.loading = false);
        }
      },
    },
    mounted() {
      this.fetchSharedDataBlock();
      this.setActiveModalTitle(`edit data block ${this.name}`);
    },
  }
</script>

<style scoped>

</style>
